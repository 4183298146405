import { Colors } from 'theme/theme.types';

const colors: Colors = {
  purple: '#4B1574',
  purple200: '#F0DCFF',
  slateGrey: '#625964',
  red: '#C83C3E',
  red200: '#FBEEF2',
  blue: '#291482',
  white: '#FFFEFC',
  beige: 'var(--The-Finery-beige-200, #FBF9F6)',
  lavender: '#F2F3F8',
};

export default colors;
