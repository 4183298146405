export interface Geofence {}

export interface DropoffAddress {
  addressType: string;
  building: string;
  city: string;
  community: string;
  customerId: string;
  geofence: Geofence;
  lat: number;
  number?: string;
  lng: number;
  street: string;
  villa: string;
}

export interface Order {
  orderAlphaId: string;
  createdAt: Date;
  orderId: string;
  geofenceSequence: number;
  pickupDate: number;
  dropoffDate: number;
  dropoffAddress: DropoffAddress;
  isRacked: boolean;
  itemsList: IOrderItem[];
  cutoffTimestamp: number;
  isUpdateApprovalExpired: boolean;
  pickupImageBags?: string[];
  originApp?: string;
  maxDelayDays?: number;
  delayStatus?: DelayStatus;
  orderStatus?: OrderStatus;
  totalDelayedItems?: number;
  isUrgent?: boolean;
  extraCareItems?: ExtraCareItems;
}
export interface IProofCollectionProps {
  pickupImageBags: string;
  pickupImageAllItems: string;
  pickupImageDoor: string;
  pickupImageRejectedRecycling: string;
  hasRecycling: boolean;
  recyclingRejected: boolean;
  pickupAddress: {
    geofenceLabel: string;
    notes: string;
    _id: string;
    customer_id: string;
    addressType: string;
    address_type: string;
    building: string;
    community: string;
    number: string;
    lat: string;
    lng: string;
    city: string;
  };
  customerId: string;
  totalPBags: number;
  totalCPBags: number;
  totalHCBags: number;
  totalWFBags: number;
  totalSCBags: number;
  totalTFBags: number;
  isCollectAtDoor?: boolean;
  pickupDurationText: string;
  pickupDate: string;
  serviceLine: string;
  pickups: any[];
}

export interface ExtraCareItems {
  [key: string]: {
    isDelicateAndStained: boolean;
    isDelayQualified: boolean;
  };
}

export interface Bucket {}
export type TApprovalStatus = 'PENDING' | 'REJECTED' | 'APPROVED' | 'PENDING_WF_TO_CP' | 'PROCEED_CP' | 'PROCEED_WF' | 'PROCEED_CP_PENDING';
export type BagTypes = 'CP' | 'P' | 'WF' | 'HC' | 'SC' | 'finery' | 'shoes';
export type serviceType = 'CP' | 'P' | 'WF' | 'HC' | 'SC' | 'shoes' | 'finery';
export type serviceLineTypes = 'laundry' | 'shoes' | 'finery';
export type CleaningType = 'CP' | 'P' | 'WF' | 'HC' | 'TF';
export type TPaymentCardTypes = 'CARD' | 'APPLE_PAY' | 'CASH' | 'PAYMENT_LINK' | 'MASTER_CARD';
export type BagTagType = 'HC' | 'Normal';
export enum PaymentMethod {
  CARD = 'CARD',
  APPLE_PAY = 'APPLE_PAY',
  CASH = 'CASH',
  PAYMENT_LINK = 'PAYMENT_LINK',
  MASTER_CARD = 'MASTER_CARD',
}
export interface ColorList {
  name: string;
  index?: number;
}
export enum DelayStatus {
  NOT_DETERMIND = 0,
  MIGHT_BE_DELAYED = 1,
  IS_DELAYED = 2,
  NOT_DELAYED = 3,
}

export type TUserPreferences = {
  stainDamageApprovals: {
    isAutoApprove: boolean;
  };
  washAndFoldApprovals: {
    inappropriateItemsAction: string | undefined;
  };
};

// Order Flow
// Customer places an order  --> order received
// Dispatcher dispatched pick up task to a driver --> Pick up assigned
// Driver starts the pick up (slider on the driver app) --> Pick up in progress
// Driver fails the task from the driver app --> order expired
// Driver picks up the bags --> Pick up complete
// Drop off date is today & driver dispatches the drop off task --> Drop off assigned
// Driver starts the Drop off (slider on the driver app) --> Drop off in progress
// Driver fails the drop off task from the driver app --> drop pff failed
// Driver drop offs packaged & hangers  --> Order completed
export enum OrderStatus {
  ORDER_RECEIVED = 0,
  PICKUP_ASSIGNED = 1,
  PICKUP_IN_PROGRESS = 2,
  PICKUP_FAILEyarnD = 2.5,
  PICKUP_COMPLETED = 3,
  DROPOFF_ASSIGNED = 4,
  DROPOFF_IN_PROGRESS = 5,
  DROPOFF_FAILED = 5.5,
  ORDER_COMPLETED = 6,
  PAYMENT_FAILED = 6.5,
  ORDER_CANCELLED = 7,
  ORDER_EXPIRED = 10,
}

export interface DetailsList {
  location: string;
  type: string;
  imageUrl: string;
  part?: string;
  reason: 'DAMAGED' | 'STAINED' | '';
  action?: string;
  isActionable?: boolean;
  isActioned?: boolean;
  isCantBeCleaned?: boolean;
  isNotCleaned?: boolean;
  isRemoved?: boolean;
  issueId: string;
}
export interface IOrderItem {
  bagCode: string;
  itemType: string | null;
  reportDetailsList: DetailsList[];
  bagType: BagTypes;
  brand: string;
  colorName: string;
  orderId: string;
  orderAlphaId: string;
  isBanned: boolean;
  isHung: boolean;
  itemCode: string;
  itemColorsList: ColorList[];
  sortedActivities: any[];
  bucket: Bucket;
  createdAt: Date;
  updatedAt: Date;
  approvalStatus?: TApprovalStatus;
  approvalOrigin?: 'FACILITY' | 'CUSTOMER' | 'SYSTEM';
  updateApprovalTimeStamp?: number;
  frontImage: string;
  delayDetails?: DelayInfo;
  wasWf?: boolean;
  price?: string;
  approvalReason?: string;
  height: number;
}

export interface IOrderApprovalItems extends IOrderItem {
  height: number;
}
interface IwashAndFoldOptionsKey {
  key: string;
  value: string;
  selected: boolean;
}
export interface StainAndDamageWashAndFoldApprovalProps {
  key: string;
  title: string;
  open: boolean;
  startIcon: JSX.Element;
  description: string;
  options?: IwashAndFoldOptionsKey[];
  isAutoApprove?: boolean;
  skippedPreferences: boolean;
}
export enum ServiceLine {
  LAUNDRY_SERVICE = 'laundry',
  SHOE_SERVICE = 'shoes',
  FINERY = 'finery',
  WASH_BAG = 'washbag',
}

export enum FACILITY_PACKAGE_TYPE {
  BANNED = 'BANNED',
  HUNG = 'HUNG',
  HUNG_LONG = 'HUNG_LONG',
  FOLDED = 'FOLDED',
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  TRANZIT = 'TRANZIT',
  MP_EXTRACTION_ARM = 'MP_EXTRACTION_ARM',
}
export enum PACKAGE_TYPE_FROM_CATEGORY {
  BANNED = 'HUNG',
  HUNG = 'HUNG',
  HUNG_LONG = 'HUNG',
  FOLDED = 'FOLDED',
  SINGLE = 'FOLDED',
  MULTIPLE = 'FOLDED',
  TRANZIT = 'FOLDED',
  MP_EXTRACTION_ARM = 'FOLDED',
}
interface DelayInfo {
  isDelayed: boolean;
  delayDays: number;
  isDelayAnswered: boolean;
  delayReason: string;
  reportedDelayTimestamp: string;
}
export interface OrderWithItems {
  order: Order;
  itemsList: IOrderItem[];
}

export interface IRootOrderResponse {
  orderWithItems: Order;
}

export interface PickupAddress {
  address_type: string;
  lng: number;
  city: string;
  geofence: string;
  street: string;
  _id: string;
  customer_id: string;
  community: string;
  lat: number;
  building: string;
  villa: string;
}

export interface DropoffAddressScd {
  addressType: string;
  building: string;
  community: string;
  street: string;
  villa: string;
}

export interface Preferences {}

export interface FirstOrderPromocode {
  is_eligible: boolean;
}

export interface SpecialAttentionItemsArray {
  brandName: string;
  stainsArray: string[];
  imageName: string;
  id: string;
  cleaningInstruction: string;
  tagsArray: string[];
}

export interface Item {
  name: string;
  shortcode: string;
  unit_price: string;
  qty: string;
  clean_type: string;
  brand: string;
  colorName: string;
}

export interface Billing {
  total_billed: string;
  promocodes: any[];
  comments: string;
  happy_hour: string;
  quick_discount: string;
  quick_promocodes: any[];
  service_fee: string;
  vat: string;
  payment_response: string;
  promo_credit: string;
  minimum_amount: string;
  total_items: string;
  promocode_type: string;
  total_wf_items: string;
  adjust_amount: string;
  total_paid: string;
  laundry: string;
  total_sale: string;
  next_day: string;
  promo_discount: string;
  hooks_charge: string;
  items: Item[];
  delivery_fee: string;
  is_careem_plus: boolean;
}

export interface City {}

export interface PickupDriver {}

export interface BillingItemsList {
  cleanType: CleaningType;
  name: string;
  quantity: any; //returns number | string
  shortCode: string;
  unitPrice: string;
  historicalPrice: number;
  bagTag: BagTagType;
  bagCode: string;
  isExtraCost: boolean;
  extraCostAmount: number;
  brand: string;
  colorName: string;
}

export interface BillingReport {
  billingItemsList: BillingItemsList[];
  promoCredit: number;
  VAT: number;
  totalPreVAT: number;
  nextDaySurcharge: string;
  serviceFee: number;
  totalPaid: number;
  promoDiscount: string;
  totalBeforeDiscount: number;
  comments: string;
  quickPromocodes: string;
  driverTip: string;
  deliveryFee: number;
  isCareemPlus: boolean;
  promocodes: string[];
}

export interface SpecialAttentionItemsList {
  id: string;
  imageName: string;
  brandName: string;
  stainsArray: string[];
  cleaningInstruction: string;
  tagsArray: string[];
}

export interface OrderReceipt {
  id: string;
  createdAt: Date;
  orderNumber: string;
  updatedAt: Date;
  createdAtTimestamp: number;
  geofence: Geofence;
  pickupDate: string;
  pickupTime: string;
  pickupTimeslotMode: string;
  pickupUserRangeStart: number;
  pickupUserRangeEnd: number;
  utcPickupDate: string;
  utcPickupTime: string;
  dropoffDate: string;
  dropoffTime: string;
  utcDropoffDate: string;
  utcDropoffTime: string;
  isCollectAtDoor: boolean;
  isDeliverAtDoor: boolean;
  creditCardId: string;
  status: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  customerAlphaId: string;
  pickupAddress: PickupAddress;
  dropoffAddress: DropoffAddressScd;
  isUrgent: boolean;
  isQuick: boolean;
  isIOHyperlocal: boolean;
  isNewOrder: boolean;
  hasCarpet: boolean;
  hasRecycling: boolean;
  preferences: Preferences;
  selectedLaundry: string;
  selectedPickupDriver: string;
  selectedDropoffDriver: string;
  superPickup: boolean;
  superDropoff: boolean;
  serviceType: string;
  deliveryTime: string;
  orderAlphaId: string;
  ioPickupHyperlocal: boolean;
  ioDropoffHyperlocal: boolean;
  activitiesIdsList: string[];
  firstOrderPromocode: FirstOrderPromocode;
  cpBags: string;
  recyclingRejected: boolean;
  dropoffCompletionTime: number;
  isConsolidated: boolean;
  isReclean: boolean;
  isFirstOrder: boolean;
  specialAttentionItemsArray: SpecialAttentionItemsArray[];
  isDropoffAtReception: boolean;
  billing: Billing;
  foldShirts: boolean;
  foldTshirts: boolean;
  foldBlouses: boolean;
  foldSweaters: boolean;
  foldPants: boolean;
  dryCleanOnly: boolean;
  handWashItems: boolean;
  hasCurtain: boolean;
  foldLungis: boolean;
  foldSkirts: boolean;
  foldShorts: boolean;
  customerStarRating: number;
  customerId: string;
  city: City;
  pickupDriver: PickupDriver;
  billingReport: BillingReport;
  specialAttentionItemsList: SpecialAttentionItemsList[];
  hooksQty: number;
  originApp: string;
  invoice: InvoiceDetails;
}
export interface IRootOrderObject {
  order: OrderReceipt;
}
export interface IRootInvoiceObject {
  invoice: InvoiceProps;
  receiptData: IReceiptProps;
  originApp: string;
  order: OrderReceipt; // temp: We need to keep the old order object until all new invoices are migrated for all partners
}
export interface PaymentsRootResponse {
  data: InvoiceDetails;
}

export interface InvoiceDetails {
  customerId: string;
  orderId: string;
  lines: {
    productId: string;
    productName: string;
    quantity: number;
    serviceType: string;
    subItems: any[];
    total: number;
    unitPrice: number;
    extraCost: {
      amount: number;
    };
    appliedDiscountsList: any[];
    lineId: number;
    baseUnitPrice: number;
  }[];
  charges: {
    minimumSurcharge: number;
    nextDaySurcharge: number;
    serviceCharge: number;
    deliveryCharge: number;
  };
  waivedCharges: {
    minimumSurcharge?: {
      amount: number;
      reasons: any[];
    };
    nextDaySurcharge?: {
      amount: number;
      reasons: string[];
    };
    serviceCharge?: {
      amount: number;
      reasons: string[];
    };
    deliveryCharge?: {
      amount: number;
      reasons: string[];
    };
  };
  discounts: {
    appliedInvoiceLevelDiscountsTotal: number;
    appliedInvoiceLinesLevelDiscountsTotal: number;
    appliedInvoiceLevelDiscountsList: any[];
  };
  credits: {
    appliedCreditsTotal: number;
    appliedCreditsList: any[];
  };
  driverTip: number;
  vat: number;
  subTotal: number;
  total: number;
  balanceDue: number;
  totalPaid: number;
  refundedAmount: number;
  refundedCreditsAmount: number;
  status: string;
  accountMap: {
    [key: string]: number;
  };
  isPaid: true;
  dueDate: number;
  unprocessedItems: any[];
  paymentLinks: any[];
  chargeAttemptsCount: number;
  chargeActions: {
    amount: string;
    isApproved: boolean;
    paymentId: string;
    performedAt: number;
    responseCode: string;
  }[];
  refundActions: {
    amount: string;
    isApproved: boolean;
    paymentId: string;
    performedAt: number;
    responseCode: string;
  }[];
}

interface ExtraCost {
  amount: number;
  reason: string;
  agent: string;
  performedAt: number;
}

interface ItemSurcharge {
  amount: number;
  waiveSurcharge: boolean;
}

interface AddOn {
  productName: string;
  quantity: number;
  unitPrice: number;
  extraCost?: ExtraCost;
  total: number;
  itemSurcharge?: ItemSurcharge;
}

interface AddOn {
  productName: string;
  quantity: number;
  unitPrice: number;
  extraCost?: ExtraCost;
  total: number;
  itemSurcharge?: ItemSurcharge;
}
export interface ItemsProps {
  productName: string;
  quantity: number;
  total: number;
  itemSurcharge?: any;
  items?: {
    productName: string;
    quantity: number;
    unitPrice: number;
  };
  extraItems?: {
    productName: string;
    quantity: number;
    unitPrice: number;
  };
}

export interface InvoiceItems {
  title?: string;
  productId?: string;
  productName?: string;
  quantity?: number;
  serviceType: serviceType;
  subItems?: {
    productName: string;
    quantity: number;
    unitPrice: number;
  };
  items: ItemsProps[];
  total?: number;
  unitPrice?: number;
  extraCost?: ExtraCost;
  addOns?: AddOn[];
  itemSurcharge?: ItemSurcharge;
}

export interface ChargeAction {
  amount: number;
  isApproved: boolean;
  paymentId: string;
  performedAt: number;
  responseCode: string;
}
export interface RefundAction {
  amount: number;
  isApproved: boolean;
  paymentId: string;
  performedAt: number;
  responseCode: string;
}
export interface IReceiptProps {
  dueDate: number | string;
  issuer: string;
  invoiceNumber: number;
  customerEmail: string;
  customerAddress: string;
  orderAlphaId: string;
  taxNumber: number;
  paymentMethod: {
    method: TPaymentCardTypes;
    cardDetails: {
      type?: TPaymentCardTypes;
      last4?: string;
    };
  };
}

export interface InvoiceProps {
  serviceLine: serviceLineTypes;
  totalAmount?: number;
  nextDaySurcharge?: number;
  serviceCharge?: number;
  deliveryCharge?: number;
  minimumSurcharge?: number;
  driverTip: number;
  vat: number;
  subTotal: number;
  total?: number;
  balanceDue: number;
  totalPaid: number;
  refundedAmount: number;
  refundedCreditsAmount?: number;
  status: string;
  dueDate?: number;
  chargeActions?: ChargeAction[];
  refundActions?: RefundAction[];
  totalDiscounts?: number;
  currency?: string;
  totalPreVat?: number;
  originApp?: string;
  lines: InvoiceItems[];
  discounts?: boolean;
  appliedCredits?: {
    amount: number;
  }[];
}
